@import '@lumapps/lumx/scss/variables';
@import "@lumapps/wrex/scss/mixins.scss";

.wrex-content-headline {
    @include wrexContentBlock();

    .theme-material & {
        @include lumx-typography('headline');
    }

    .theme-lumapps & {
        @include lumx-typography('title2', 'custom');
    }

    .theme-lumapps .block-comments &,
    .theme-lumapps .block-post &,
    .theme-lumapps .posts-post-dialog & {
        @include lumx-typography('title5', 'custom');
    }
}
