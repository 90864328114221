@import '@lumapps/lumx/scss/variables';

.filters-dropdown {
    &__header {
        justify-content: space-between;
        margin-bottom: $lumx-spacing-unit;
    }

    &__filters {
        padding: 0 $lumx-spacing-unit-huge;
        min-width: 300px;
        max-width: 400px;
        /** allow scrolling inside the filters dropdown */
        overflow: auto;
        min-height: unset !important;
        /** roughly around 4 filters and a bit of the next one */
        max-height: 400px;
    }

    &__footer {
        justify-content: space-between;
        /**
          * Remove the padding from the toolbar at the bottom of the dialog
          * so that the buttons at the bottom are next to each other accordingly
          */
        .lumx-toolbar__before {
            padding: 0 !important;
        }
    }
}
