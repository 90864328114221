@import '@lumapps/lumx/scss/variables.scss';

$notification-center-width: 400px;

.notification-center-list {
    &__list {
        width: 100%;
        max-width: $notification-center-width;
        padding: 0;
    }

    &__sub-header {
        border-bottom: 1px solid lumx-color-variant('dark', 'L5');
        padding: 0 $lumx-spacing-unit-big;
    }

    &__item:not(:last-child) {
        border-bottom: 1px solid lumx-color-variant('dark', 'L5');
    }
    &__skeleton:not(:last-child) {
        border-bottom: 1px solid lumx-color-variant('dark', 'L5');
    }
}

.notification-center-empty-list {
    padding: $lumx-spacing-unit * 8;
    width: $notification-center-width;
}

.notification-center-error-state {
    padding: $lumx-spacing-unit * 8;
    width: $notification-center-width;
}
