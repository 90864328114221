@import '@lumapps/lumx/scss/variables.scss';

.instance-logo {
    display: block;
    height: var(--lumx-app-header-logo-height);

    img {
        max-height: 100%;
        max-width: 100%;
        vertical-align: bottom;
    }

    @include mq($until: mobile) {
        height: 20px;
    }
}
