.lumx-select-text-field {
  .lumx-text-field__chips {
      min-width: 0;
      width: min-content;
  }

  &__list {
    max-height: 50vh;
  }
}
