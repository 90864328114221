@import '@lumapps/lumx/scss/variables.scss';

.combobox-option {
    position: relative;

    &__content {
        // TODO: migrate away from using lumx-list-item styles https://lumapps.atlassian.net/browse/DSW-288
        gap: 0;

        &--is-disabled {
            opacity: .5;
        }
    }

    &__trigger {
        color: $lumx-color-dark-N;
        text-decoration: none;
        overflow: hidden;

        // Make the whole item clickable
        &::before {
            content: '';
            cursor: pointer;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }

    &__after {
        z-index: 3;
    }
}
