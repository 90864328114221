@import '@lumapps/lumx/scss/variables';
@import './variables.scss';

@mixin wrexContentBlock() {
    margin-bottom: $lumx-spacing-unit-huge;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;

    &:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }

    $sides: start center end justify;
    @each $side in $sides {
        &.wrex-text-align-#{$side} {
            text-align: $side;
        }
    }
}

@mixin wrexContentBlockWithAction() {
    position: relative;

    &__actions {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        margin-top: $lumx-spacing-unit-regular;
        margin-right: $lumx-spacing-unit-regular;
    }

    &:hover &__actions,
    &--selected &__actions {
        display: block;
        &--is-multiple {
            overflow: hidden;
            display: flex;
            flex-direction: row-reverse;
            gap: $lumx-spacing-unit-regular;
        }
    }

    &--editable {
        position: relative;
        cursor: pointer;
    }

    &--selected {
        border-radius: 2px;
        box-shadow: 0 0 0 $wrex-block-border-width lumx-color-variant('primary', 'N');
    }

    &--editable:hover:not(&--selected) {
        border-radius: 2px;
        box-shadow: 0 0 0 $wrex-block-border-width lumx-color-variant('primary', 'L2');
    }
}
