.custom-icon {
    &-blue {
        background-color: rgba(54, 109, 241, 0.1) !important;
        color: rgba(54, 109, 241, 0.6) !important;
    }

    &-red {
        background-color: rgba(208, 45, 43, 0.1) !important;
        color: rgba(208, 45, 43, 0.6) !important;
    }

    &-orange {
        background-color: rgba(239, 166, 9, 0.1) !important;
        color: rgba(239, 166, 9, 0.6) !important;
    }

    &-green {
        background-color: rgba(24, 142, 70, 0.1) !important;
        color: rgba(24, 142, 70, 0.6) !important;
    }

    &-violet {
        background-color: rgba(93, 40, 178, 0.1) !important;
        color: rgba(93, 40, 178, 0.6) !important;
    }

    &-yellow {
        background-color: rgba(244, 182, 10, 0.1) !important;
        color: rgba(244, 182, 10, 0.6) !important;
    }
}
