@import '@lumapps/lumx/scss/variables.scss';

.skip-link {
    padding: $lumx-spacing-unit;
    background: $lumx-color-dark-N;
    transition: opacity $fade-speed ease-in-out;
    opacity: 0;
    pointer-events: none;
    text-wrap: nowrap;

    &:focus {
        opacity: 1;
        pointer-events: all;
    }

    &--floating {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;

    }
}
