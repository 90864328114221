@import '@lumapps/lumx/scss/variables.scss';

.user-profile-dropdown {
    display: block;
    border-radius: 50% !important;
    height: 36px;
    width: 36px;
    padding: 0 !important;

    .lumx-avatar {
        cursor: pointer;

        &::after {
            @include lumx-state-transition;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            content: '';
            pointer-events: none;
        }
    }

    &:hover {
        .lumx-avatar {
            &::after {
                @include lumx-state(lumx-base-const('state', 'HOVER'), lumx-base-const('emphasis', 'LOW'), 'dark');
            }
        }
    }

    &:active {
        .lumx-avatar {
            &::after {
                @include lumx-state(lumx-base-const('state', 'HOVER'), lumx-base-const('emphasis', 'LOW'), 'dark');
            }
        }
    }

    &__menu-content {
        min-width: $lumx-size-xxl;
    }
}
