@import '@lumapps/lumx/scss/variables';

.wrex-user-mention {
    $self: &;
    display: inline-block;

    > span {
        font-weight: 700;
    }

    color: lumx-color-variant('dark', 'L2');
    .wrex-editor--theme-dark & {
        color: lumx-color-variant('light', 'L2');
    }

    &--is-editing {
        cursor: text;

        &:hover {
            text-decoration: none;
        }
        /** Hovered state outline */
        &:not(#{$self}--is-selected):hover {
            box-shadow: 0 0 0 2px lumx-color-variant('primary', 'L3');
        }
        /** Selected state outline */
        &#{$self}--is-selected {
            box-shadow: 0 0 0 2px lumx-color-variant('primary', 'N');
        }
    }
}
