@import '@lumapps/lumx/scss/variables.scss';

.assistant-widget {
    @include lumx-elevation(2);
    position: fixed !important;
    bottom: $lumx-spacing-unit-huge;
    right: $lumx-spacing-unit-huge;
    overflow: hidden;
    .lumx-thumbnail__background {
        background-color: var(--assistant-widget-button-background-color, var(--assistant-header-background-color, var(--lumx-color-primary-N)));
        img {
            object-fit: contain !important;
        }
    }
    &--square {
        border-radius: $lumx-border-radius;
    }
    &--round {
        border-radius: 50%;
    }
}
