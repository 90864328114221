@import '@lumapps/lumx/scss/variables.scss';

.bookmark {
    flex: 0;
    max-width: 33%;
    list-style: none;

    &__link {
        @include lumx-list-item-clickable;
        @include lumx-link-base;
        display: block;
        width: $lumx-spacing-unit * 11;
        padding: $lumx-spacing-unit;
        margin: $lumx-spacing-unit;
        border-radius: $lumx-border-radius * 4;
        text-align: center;

        &:focus {
            @include lumx-list-item-selected;
        }
    }

    &-skeleton {
        width: $lumx-spacing-unit * 11;
        padding: $lumx-spacing-unit $lumx-spacing-unit-big;
        margin: $lumx-spacing-unit;
    }

    &__icon {
        width: $lumx-spacing-unit * 6;
        height: $lumx-spacing-unit * 6;
        margin: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__title {
        overflow: hidden;
        margin-top: $lumx-spacing-unit;
        color: black;
        text-overflow: ellipsis;
    }
}
