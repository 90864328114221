@import '@lumapps/lumx/scss/variables';

.text-field-filter {
    padding-top: 0 !important;

    /**
     * Override typography for the select label so that it looks
     * like the filters
     */
    .lumx-text-field__label {
        @include lumx-typography('subtitle1');
    }
}
