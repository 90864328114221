@import "@lumapps/lumx/scss/variables";

.customization-app {
    position: absolute;
    top: 0;
    z-index: 10000;

    &__right {
        right: 0;
    }

    &__left {
        left: 0;
    }
}

.customizations-wrapper {
    @include wrapper;
}
