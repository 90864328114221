@import '@lumapps/lumx/scss/variables';

.notification-stack {
    position: absolute;
    right: 0;
    bottom: 0;
    width: fit-content;
    // Use the same value as the DS Notification as this component should always be on top
    z-index: 9999;

    > * {
        position: fixed;
        right: $lumx-spacing-unit-huge;
        bottom: $lumx-spacing-unit-huge;
        animation: unset;

        @media (prefers-reduced-motion: no-preference) {
            transition: all $lumx-notification-transition-duration;
        }
    }
}
