.lumx-selection-chip-group {
  max-width: 100%;

  &:empty {
    display: none;
  }

  &__item {
    display: flex;
    overflow: hidden;
  }

  &__chip {
    overflow: hidden;

    .lumx-chip__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
