@import "@lumapps/lumx/scss/variables";
@import "@lumapps/wrex/scss/mixins.scss";

.wrex-content-list-item {
    --_marker-color: none;
    @include wrexContentBlock();
    margin-bottom: 0;
    margin-left: $lumx-spacing-unit-big * 3;

    &::marker {
        color: var(--_marker-color);
    }
}
