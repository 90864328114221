@import '@lumapps/lumx/scss/variables.scss';

.notification-center-list-header {

    &__with-filters {
        border-bottom: 1px solid lumx-color-variant('dark', 'L5');
    }

    &__chip-skeleton {
        margin: $lumx-spacing-unit-tiny;
        width: map-get($lumx-sizes, 'l');
    }

}
