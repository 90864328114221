@import '@lumapps/lumx/scss/variables.scss';

.main-nav {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
    .theme-lumapps & {
        box-shadow: var(--lumx-app-header-box-shadow);
        border-bottom-style: solid;
        border-bottom-width: var(--lumx-app-header-border-bottom-width);
        border-bottom-color: var(--lumx-app-header-border-bottom-color);
    }

    &__wrapper {
        @include wrapper;
    }

    &__root {
        body.front-office & {
            display: flex;
            height: $header-navigation-height;
            align-items: center;
        }

        padding: 0;
        // As the items have horizontal padding, we need to add negative margins to root
        margin: 0 $lumx-spacing-unit * -1.5;
        list-style: none;
    }

    &--is-disabled {
        @include disabled();
    }
}


.main-nav-item {
    $item: &;
    display: flex;
    align-items: center;
    color: $lumx-color-dark-L2;

    &--skeleton {
        margin-right: $lumx-spacing-unit-big;

        #{$item}__label {
            height: $header-navigation-height;
        }

        .skeleton {
            width: map-get($lumx-sizes, 'xl');
        }
    }

    &__icon {
        margin-right: $lumx-spacing-unit-tiny;
    }

    &__link {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        // Normalize link
        background: transparent;
        border: none;
        text-decoration: none;
        color: $lumx-color-dark-N;
        padding: 0;

    }

    &__label {
        @include lumx-typography('subtitle1');
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
        text-align: left;
    }

    &__icon-indicator {
        margin-left: $lumx-spacing-unit;
    }

    /**
    *  Add some extra spacing inside items that are also toggles
    *  to compensate the fact that the icon is not a button.
    */
    #{$item}__icon-indicator {
        margin-right: $lumx-spacing-unit-tiny;
    }

    /**
    * /!\ Nested submenus are now actually nested in the dom /!\
    * Use > to target current level elements.
    */
    & > #{$item}__link,
    & > #{$item}__link #{$item}__icon-indicator,
    & > #{$item}__button-indicator {
        // Inherit the parent color
        color: inherit;

        // Remove default button backgroud on hover
        &:hover,
        &[data-focus-visible-added] {
            background: none;
        }
    }

    /* Override some default button styling  */
    button {
        // remove the round border-radius
        border-radius: $lumx-border-radius !important;

        // buttons should not have cursor
        &:hover {
            cursor: default !important;
        }
    }

    /** active, hover and focus style */
    &--is-active,
    &--is-hovered,
    &:hover > button,
    &:hover > a,
    [data-focus-visible-added] {
        color: $lumx-color-primary-N;
    }

    [data-focus-visible-added] {
        @include lumx-state(lumx-base-const('state', 'FOCUS'), lumx-base-const('emphasis', 'LOW'), 'dark');
    }

    /** Add scroll on submenu */
    &__submenu {
        overflow-y: auto;
    }

    /** Primary level links */
    &--primary {
        /**
        * Add padding on both sides of items to make it possible
        * to customize the background on hover.
        */
        padding-left: $lumx-spacing-unit * 1.5;
        padding-right: $lumx-spacing-unit * 1.5;

        /**
        * When items have subitems, an arrow appears and adds extra space.
        * In that case, decrease initial space a bit...
        */
        &#{$item}--has-items {
            padding-right: $lumx-spacing-unit-tiny;
        }

        & > #{$item}__link {
            height: $header-navigation-height;
        }
    }

    /** Secondary level links (sublinks) */
    &--secondary {
        @include lumx-side-navigation-wrapper;
        padding: 0 $lumx-spacing-unit;

        #{$item}__label {
            padding: $lumx-spacing-unit;
        }

        #{$item}__icon-indicator {
            @include lumx-side-navigation-link-icon(lumx-base-const('position', 'RIGHT'));
        }
    }
}
