.follow-button {
    &__grid {
        display: grid;
    }
    &__active {
        grid-area: 1 / 1;
        visibility: visible;
    }

    &__idle {
        grid-area: 1 / 1;
        visibility: hidden;
    }
}
