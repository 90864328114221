@import '@lumapps/lumx/scss/variables.scss';

.notification-center-confirm-modal {
    &__title {
        @include lumx-typography('title');
        display: flex;
    }

    &__icon {
        margin-right: $lumx-spacing-unit-regular;
    }

    &__description {
        @include lumx-typography('body2');
        padding: $lumx-spacing-unit-huge;
    }
}
