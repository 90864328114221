@use "sass:map";
@import '@lumapps/lumx/scss/variables.scss';

.directory-favorite {
  &__show-all-link {
    // Use custom-button
    font-family: var(--lumx-typography-custom-button-font-family);
    font-style: var(--lumx-typography-custom-button-font-style);
    // Use custom-button or fallback to subtitle1
    font-size: var(--lumx-typography-custom-button-font-size, var(--lumx-typography-interface-subtitle1-font-size));
    font-weight: var(--lumx-typography-custom-button-font-weight, var(--lumx-typography-interface-subtitle1-font-weight));
    line-height: var(--lumx-typography-custom-button-line-height, var(--lumx-typography-interface-subtitle1-line-height));
  }
}
