@import '@lumapps/lumx/scss/variables.scss';

.content-header-overlay {
  $this: &;

  &--gradient {
    background-color: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2052) 100%);
  }

  &--overlay {
    background-color: $lumx-color-dark-L3;
  }

  &--text-background {
    #{$this}__title,
    #{$this}__description {
      background-color: $lumx-color-dark-L3;
    }
  }

  &__wrapper {
    @include wrapper;
    height: 100%;
  }

  &__title {
    font-size: 48px;
    line-height: 56px;
  }

  &__description {
    padding: $lumx-spacing-unit 0;
  }
}
