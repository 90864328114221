@import '@lumapps/lumx/scss/variables.scss';

.user-card-popover {
    padding: $lumx-spacing-unit-big;

    .user-card {
        box-shadow: none;
        padding: 0;
        min-height: 0;
    }
}
