@import '@lumapps/lumx/scss/variables.scss';

.footer {
    background-color: lumx-color-variant('light', 'N');
    grid-row-end: 3;
    grid-row-start: 2;

    .wrapper {
        @include wrapper();
        margin: 0 auto;
    }
}
