@import '@lumapps/lumx/scss/variables.scss';

.header-slideshow {
  $this: &;
  position: relative;
  z-index: 0;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__controls {
    margin-top: $lumx-spacing-unit;
  }

  &--text-background {
    #{$this}__nav {
      background-color: $lumx-color-dark-L2;
    }
  }
}
