@import '@lumapps/lumx/scss/variables.scss';

.search-result-attachments {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: $lumx-spacing-unit-tiny;
    }
}
