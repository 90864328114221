@import '@lumapps/lumx/scss/variables.scss';

$padding: $lumx-spacing-unit;
$arrow-width: $lumx-size-m;
$arrow-gradient-margin: $lumx-size-s;

.lumx-arrow-side-scroll-container {
  $self: &;
  // Hidden variable used to control the masking left/right gradients
  --_arrow-right-alpha: 1;
  --_arrow-left-alpha: 1;

  position: relative;
  // Negative margin to compensate the content padding
  margin: -$padding;
  // Max width compensated with the content padding
  max-width: calc(100% + $padding * 2);

  &__scroll {
    // Add padding to avoid cropping focus outlines of the children
    padding: $padding;

    // Mask the left and right with a gradient when the left or right arrow are present
    mask: linear-gradient(
                    to left,
                    rgba(0, 0, 0, var(--_arrow-right-alpha)) $padding + $arrow-width,
                    rgba(0, 0, 0, 1) $padding + $arrow-width + $arrow-gradient-margin
          ) right / 51% 100%,
          linear-gradient(
                    to right,
                    rgba(0, 0, 0, var(--_arrow-left-alpha)) $padding + $arrow-width,
                    rgba(0, 0, 0, 1) $padding + $arrow-width + $arrow-gradient-margin
          ) left / 51% 100%;
    mask-repeat: no-repeat;

    // Setup the scroll zone
    overflow: auto;

    // 1. Hide the scrollbar (replaced with arrow buttons)
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    // 2. Snap scroll to children
    scroll-snap-type: x proximity;

    // 3. Smooth scroll when the user does not prefer reduced motions
    scroll-behavior: smooth;
    @media (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
    }

    // This forces the use of the ArrowSideScrollContainer.Item component in children
    &:not(:has(#{$self}__item)) {
        display: none;
    }
  }

  // Arrow to the left/right of the scroll zone
  &__arrow {
    z-index: 1;
    position: absolute;
    top: $padding;
    bottom: $padding;
    flex-shrink: 0;
    margin-block: auto;
    height: auto !important;

    &--left {
      left: $padding;
    }
    &--right {
      right: $padding;
    }
  }

  // Item
  &__item {
    scroll-snap-align: start;
    scroll-margin-left: $padding + $arrow-gradient-margin + $arrow-width;
    // Bigger margin on the right to try to overcome the scroll snap when scrolling item into view from the right
    scroll-margin-right: $padding + $arrow-gradient-margin + ($arrow-width*2);
  }
}
