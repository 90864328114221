@import '@lumapps/lumx/scss/variables.scss';

.language-switcher {
    &__button {
        &--no-label {
            i:first-child{
                margin-right: 0 !important;

            }
        }
    }
}
