@import '@lumapps/lumx/scss/variables';
@import '@lumapps/wrex/scss/mixins.scss';

.wrex-content-code-block {
    @include wrexContentBlock();
    background-color: $lumx-color-dark-L6;
    padding: $lumx-spacing-unit-tiny $lumx-spacing-unit;
    border: solid 1px $lumx-color-dark-L5;
    border-radius: $lumx-spacing-unit-tiny * 0.5;
    &--theme-dark {
        background-color: lumx-color-variant('light', 'L6');
        border: solid 1px lumx-color-variant('light', 'L4');
    }
    .wrex-editor--theme-dark & {
        background-color: lumx-color-variant('light', 'L6');
        border: solid 1px lumx-color-variant('light', 'L4');
    }
}
