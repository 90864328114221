@import '@lumapps/lumx/scss/variables';

.filter-trigger {
    &__trigger {
        max-width: 100%;

        .lumx-chip__label {
            min-width: 0;
        }
    }
}
