@import '@lumapps/lumx/scss/variables.scss';

.notification-center-menu {
    position: relative;
    width: map-get($lumx-sizes, 'm');
    height: map-get($lumx-sizes, 'm');

    &__badge {
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: 3;
        pointer-events: none;
    }

    &__button {
        &--is-active {
            background-color: lumx-color-variant('dark', 'L5') !important;
        }
    }

    &__popover > &__popover-content {
        overflow: auto;
        /*
         * The React FlexBox component doesn't allow to set a ref on it.
         * Apply style manually.
         */
        display: flex;
        min-height: 0;
        flex-direction: column;
    }
}
