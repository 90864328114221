@import '@lumapps/lumx/scss/variables.scss';

.keyboard-key {
    @include lumx-typography('body1');
    padding: 0 $lumx-spacing-unit-tiny;
    background-color: lumx-color-variant("light", "L3");
    border: 1px solid lumx-color-variant("dark", "L5");
    border-radius: 5px;
    display: inline;

    i {
        display: inline-block;
    }
}
