/**
 * Navigation and customizations styles need to be imported into the main bundle rather than downloading them lazily. This is because
 * if this code is downloaded lazily, it will be applied after our customers customizations, overriding them. Importing
 * them here makes them load before these customizations, therefore applying them correctly.
 */
@import '@lumapps/navigation/index.scss';
@import '@lumapps/customizations/api/components/index.scss';

body.front-office {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}

#front-office-app {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    body:not(.headless):not(.navigation-inheritance):not(.not-sticky):not(.no-top-bar) & {
        @include mq($until: desktop) {
            padding-top: $header-top-bar-height;
        }

        @include mq($from: desktop) {
            padding-top: $header-height;
        }
    }

    body:not(.headless).navigation-inheritance:not(.not-sticky) & {
        padding-top: $header-top-bar-height;
    }
}
