@import '@lumapps/lumx/scss/variables';
@import '@lumapps/wrex/scss/mixins.scss';

.wrex-content-link {
    @include wrexContentBlock();
    display: inline;
    
    .theme-material & {
        @include lumx-typography('body1');
    }

    .theme-lumapps & {
        @include lumx-typography('body-large', 'custom');
    }

    .theme-lumapps .block-comments &,
    .theme-lumapps .block-post &,
    .theme-lumapps .posts-post-dialog & {
        @include lumx-typography('body', 'custom');
    }
}
