@import '@lumapps/lumx/scss/variables.scss';

.combobox-listbox {
  &:empty {
    padding: 0;
  }

  &__state {
    text-align: center;
    margin: $lumx-spacing-unit-regular;
  }
}
