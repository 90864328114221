@import '@lumapps/lumx/scss/variables.scss';

.user-card {
    position: relative;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    padding: $lumx-spacing-unit-huge;
    margin-bottom: $lumx-spacing-unit-tiny;
    min-height: 150px;
    width: 240px;

    &__preview-edit-wrapper {
        display: flex;
        gap: $lumx-spacing-unit-tiny;
        position: absolute;
        top: $lumx-spacing-unit;
        right: $lumx-spacing-unit;
    }
}
