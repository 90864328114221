@import '@lumapps/lumx/scss/variables.scss';

.notification-list-item {
    &--clickable {
        @include lumx-list-item-clickable;
        cursor: default;
    }

    &__content {
        padding: $lumx-spacing-unit-big $lumx-spacing-unit-huge;
        color: $lumx-color-dark-N;
        text-decoration: none;
    }

    &__menu {
        padding: $lumx-spacing-unit-big $lumx-spacing-unit-huge;
        padding-left: 0;
    }

    &__before {
        flex-shrink: 0;
    }

    .lumx-list-item__wrapper {
        padding: 0;
    }
}
