@import '@lumapps/lumx/scss/variables.scss';

.search-result-thumbnail {
    .lumx-thumbnail__focused-image,
    .lumx-thumbnail__image {
        width: auto;
        height: inherit;
        min-width: 100%;
        min-height: 100%;
    }
}
